<template>
  <aside class="sidebar" v-click-outside="hideSidebar">
    <div class="top-item">
      <h1 class="h2"><span class="icon-snippets"></span>Snippets</h1>
      <form class="search-form" action="#">
        <div class="input">
          <input type="text" v-model="search" placeholder="Filter for snippets">
          <button type="submit"><span class="icon-search"></span></button>
        </div>
      </form>
    </div>

    <div class="scrollable-area scrollbar-track-y">
      <nav class="nav">
        <ul v-if="fiteredList.length">
          <li v-for="snippet in fiteredList" :key="snippet.id" ><router-link :to="'/snippet/'+ snippet.id">{{ snippet.title }}</router-link></li>
        </ul>
      </nav>
    </div>
    <nav class="main-nav">
      <ul>
        <li><router-link :to="'/articles'"><span class="icon-note"></span>Articles</router-link></li>
        <li><router-link :to="'/notes'"><span class="icon-note"></span>Notes <i class="icon-lock"></i></router-link></li>
        <li><router-link :to="'/mixins'"><span class="icon-mixins"></span>SCSS Mixins</router-link></li>
      </ul>
    </nav>
  </aside>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        search: '',
      }
    },
    computed: {
      ...mapState(['snippets']),
      fiteredList() {
        return this.snippets.filter(post => post.title.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    methods: {
      hideSidebar() {
        // document.body.classList.remove('nav-active')
      }
    }
  }
</script>