<template>
<div class="content">
    <section class="notes">
        <form action="#" class="search-note">
          <div class="input-wrap">
            <input type="search" placeholder="Search Note" v-model.trim="search">
          </div>
          <button class="btn" @click="createPost">Add Note</button>
        </form>
        <div class="row" v-if="selectedNotes.length">
            <Notes :note="note" v-for="note in selectedNotes" :key="note.id" @del="confirm" />
        </div>
        <div v-else class="no-result">Note not found.</div>
    </section>
    <ConfirmModal title="Are you sure want to delete this Note?" @delete="del" @close="closeModal" v-if="modal" />
</div>
</template>

<script>
import { mapState } from 'vuex'
import { notesCollection } from '@/firebase'
import Notes from './components/Notes'
import ConfirmModal from '../../components/ConfirmModal'
export default {
    title: 'Note',
    data() {
        return {
            modal: false,
            selectedId: '',
            search: ''
        }
    },
    components: {
        Notes,
        ConfirmModal,
    },
    computed: {
        ...mapState(['userProfile', 'notes']),
        selectedNotes() {
            return this.notes.filter(note => note.userId === this.userProfile.id && note.content.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    methods: {
        createPost() {
            this.$store.dispatch('createNote', {
                content: 'Title goes here'
            })
            this.content = ''
        },
        confirm(id) {
            this.modal = true
            this.selectedId = id
        },
        del() {
            notesCollection.doc(this.selectedId).delete()
            this.closeModal()
            this.selectedId = ''
        },
        closeModal() {
            this.modal = false
        }
    },
    mounted() {}
}
</script>
