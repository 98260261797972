import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

/*eslint no-unused-vars: ["error", { "args": "none" }]*/
fb.postsCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  let postsArray = []

  snapshot.forEach(doc => {
    let post = doc.data()
    post.id = doc.id

    postsArray.push(post)
  })

  store.commit('setPosts', postsArray)
})

fb.snippetsCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  let postsArray = []

  snapshot.forEach(doc => {
    let post = doc.data()
    post.id = doc.id

    postsArray.push(post)
  })

  store.commit('setSnippets', postsArray)
})

fb.notesCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  let notesArray = []

  snapshot.forEach(doc => {
    let note = doc.data()
    note.id = doc.id

    notesArray.push(note)
  })

  store.commit('setNotes', notesArray)
})

fb.categoriesCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  let catsArray = []

  snapshot.forEach(doc => {
    let cat = doc.data()
    cat.id = doc.id

    catsArray.push(cat)
  })

  store.commit('setCats', catsArray)
})

const store = new Vuex.Store({
  state: {
    userProfile: {},
    posts: [],
    snippets: [],
    notes: [],
    cats: [],
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPosts(state, val) {
      state.posts = val
    },
    setSnippets(state, val) {
      state.snippets = val
    },
    setError(state, val) {
      state.error = val
    },
    setNotes(state, val) {
      state.notes = val
    },
    setCats(state, val) {
      state.cats = val
    },
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password).catch(error => { this.commit('setError', error.message) })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // userProfile.id = user.uid
      let userData = userProfile.data()
      userData.id = user.uid

      // set user profile in state
      commit('setUserProfile', userData)

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user profile object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
        title: form.title,
        image: form.image,
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async logout({ commit }) {
      await fb.auth.signOut()

      // clear userProfile and redirect to /login
      commit('setUserProfile', {})
      router.push('/login')
    },
    async createPost({ state, commit }, post) {
      await fb.postsCollection.add({
        createdOn: new Date(),
        content: post.content,
        title: post.title,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,
        comments: 0,
        likes: 0,
        category: post.category
      })
    },
    async likePost({ commit }, post) {
      const userId = fb.auth.currentUser.uid
      const docId = `${userId}_${post.id}`

      // check if user has liked post
      const doc = await fb.likesCollection.doc(docId).get()
      if (doc.exists) {
        await fb.likesCollection.doc(docId).delete()
        fb.postsCollection.doc(post.id).update({
          likes: post.likesCount - 1
        })
      } else {
        // create post
        await fb.likesCollection.doc(docId).set({
          postId: post.id,
          userId: userId
        })

        // update post likes count
        fb.postsCollection.doc(post.id).update({
          likes: post.likesCount + 1
        })
      }
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      await fb.usersCollection.doc(userId).update({
        name: user.name,
        title: user.title,
        image: user.image
      })

      dispatch('fetchUserProfile', { uid: userId })

      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      postDocs.forEach(doc => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.name
        })
      })

      // update all comments by user
      const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      commentDocs.forEach(doc => {
        fb.commentsCollection.doc(doc.id).update({
          userName: user.name
        })
      })
    },
    async createSnippet({ state, commit }, post) {
      await fb.snippetsCollection.add({
        createdOn: new Date(),
        title: post.title,
        html: post.html,
        js: post.js,
        scss: post.scss,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name
      })
    },
    async createNote({ state, commit }, note) {
      await fb.notesCollection.add({
        createdOn: new Date(),
        content: note.content,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name
      })
    },
    async createCat({ state, commit }, cat) {
      await fb.categoriesCollection.add({
        createdOn: new Date(),
        name: cat.name,
      })
    },
  },
  modules: {
  }
})

export default store