import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Notes from '../views/notes/Index.vue'
import Mixins from '../views/Mixins.vue'
import Articles from '../views/articles/Index.vue'
import ArticleShow from '../views/articles/Show.vue'
import ArticleNew from '../views/articles/New.vue'
import Snippet from '../views/Snippet.vue'
import createSnippet from '../views/createSnippet.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/snippet/:id',
    name: 'snippet',
    component: Snippet,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/snippet',
    name: 'create-snippet',
    component: createSnippet,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/new',
    name: 'article-new',
    component: ArticleNew,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/:id',
    name: 'articles-show',
    component: ArticleShow,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notes',
    name: 'notes',
    component: Notes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mixins',
    name: 'mixins',
    component: Mixins,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// no-unused-vars
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const resetPass = to.fullPath.includes('resetPassword')

  if (requiresAuth && !auth.currentUser) {
    if(!resetPass)
      next('/login')
    else
      next('/reset-password'+ to.fullPath)
  } else {
    next()
  }
})

export default router
