<template>
  <div class="col" :class="{ 'zoom-active': isZoom }" v-click-outside="zoomInactive">
    <div :id="'a' + index" v-for="(c, index) in clone" :key="index">
      <div class="bg" v-if="index == 1" @click="zoomInactive"></div>

      <Item :note="note" @zoomAct="zoomActive" />
    </div>
  </div>
</template>

<script>
import Item from "./Item";
export default {
  props: ["note"],
  components: {
    Item,
  },
  data() {
    return {
      isOpen: false,
      isZoom: false,
      clone: [1],
    };
  },
  computed: {
      countHeight() {
          return this.clientHeight
      }
  },
  methods: {
    zoomActive() {
      if (!this.isZoom) this.boxClone();
      setTimeout(() => {
        this.isZoom = true;
      }, 200);
    },
    zoomInactive() {
      this.isZoom = false;
      setTimeout(() => {
        this.boxRemove();
      }, 200);
    },
    boxClone() {
      this.clone.push(2);
    },
    boxRemove() {
      this.clone.splice(1, 1);
    },
  },
};
</script>
