<template>
  <div>
    <ul class="tabs">
        <!--<li class="active"><a href="#">PREVIEW</a></li> -->
        <li v-if="selectedPost.html" :class="{ 'active' : type == 'html' }"><a href="#" @click.prevent="view(selectedPost.html, 'html')">HTML</a></li>
        <li v-if="selectedPost.scss" :class="{ 'active' : type == 'scss' }"><a href="#" @click.prevent="view(selectedPost.scss, 'scss')">SCSS</a></li>
        <li v-if="selectedPost.js" :class="{ 'active' : type == 'js' }"><a href="#" @click.prevent="view(selectedPost.js, 'js')">JS</a></li>
    </ul>
    <div class="content-area">
      <div class="posted-by">
        <div>{{ selectedPost.userName }}</div>
        <div>{{ stitle }}</div>
        <div>
          <div>{{ selectedPost.createdOn | formatDate }}</div>
          <template v-if="editAccess">
            <button v-if="readOnly" @click="edit" class="btn">Edit</button>
            <button v-else @click="update" class="btn">Save</button>
          </template>
          <button v-if="editAccess" @click="openModal" class="btn">Delete</button>
        </div>
      </div>
      <div class="ca-prism">
        <prism-editor :readonly="readOnly" class="my-editor" v-model="tab" :highlight="highlighter" line-numbers></prism-editor>
      </div>
    </div>
    <ConfirmModal title="Are you sure want to delete this snippet?" @delete="del" @close="closeModal" v-if="modal" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { snippetsCollection } from '@/firebase'
import ConfirmModal from '../components/ConfirmModal'

export default {
  data() {
    return {
      selectedPost: {},
      tab: '',
      stitle: '',
      type: 'html',
      readOnly: true,
      modal: false,
    }
  },
  components: {
    ConfirmModal,
  },
  computed: {
    ...mapState(['userProfile', 'snippets']),
    editAccess () {
      return (this.userProfile.name === this.selectedPost.userName)
    }
  },
  watch: {
    '$route': 'getSnippet',
  },
  methods: {
    getSnippet() {
      this.selectedPost = this.snippets.find(x => x.id === this.$route.params.id )
      this.tab = this.selectedPost.html
      this.stitle = this.selectedPost.title
      this.titleUpdated()
    },
    highlighter(code) {
      return highlight(code, languages.js, "js");
    },
    view(el, ty) {
      this.tab = el
      this.type = ty
    },
    edit() {
      this.readOnly = false
    },
    async del() {
      const id = this.$route.params.id;
      await snippetsCollection.doc(id).delete();
      this.closeModal();
    },
    async update() {
      const id = this.$route.params.id
      this.readOnly = true
      // console.log(this.type)
      // update user object
      if(this.type === 'html') {
        await snippetsCollection.doc(id).update({
          html: this.tab,
        })
      }
      if(this.type === 'js') {
        await snippetsCollection.doc(id).update({
          js: this.tab,
        })
      }
      if(this.type === 'scss') {
        await snippetsCollection.doc(id).update({
          scss: this.tab,
        })
      }

      this.selectedPost = this.snippets.find(x => x.id === this.$route.params.id )
    },
    titleUpdated() {
      document.title = `Snippet | ${this.stitle}`
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    }
  },
  filters: {
    formatDate(val) {
      if (!val) { return '-' }
      
      let date = val.toDate()
      return moment(date).fromNow()
    },
  },
  created() {
    this.getSnippet(),
    this.titleUpdated()
  }
}
</script>
