<template>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-box">
          <h3 v-html="title"></h3>
          <br>
          <button @click="$emit('delete')" class="btn">Yes</button> &nbsp;
          <button @click="$emit('close')" class="btn">No</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
}
</script>