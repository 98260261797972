import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import './plugins/prism'
import './mixins'
import ClickOutside from 'vue-click-outside'
import './assets/scss/app.scss'
import 'vueditor/dist/style/vueditor.min.css'
import vueditor from 'vueditor-fork'

Vue.use(vueditor, Vuex);

require('./filters/index')

Vue.directive('click-outside', ClickOutside)

Vue.config.productionTip = false


let app
auth.onAuthStateChanged(user => {
  if(!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})

