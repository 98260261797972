import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import * as consts from './config'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: consts.APIKEY,
  authDomain: consts.AUTHDOMAIN,
  databaseURL: consts.DATABASEURL,
  projectId: consts.PROJECTID,
  storageBucket: consts.STORAGEBUCKET,
  messagingSenderId: consts.MESSAGINGSENDERID,
  appId: consts.APPID
}
const firebaseApp = firebase.initializeApp(firebaseConfig)

// utils
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const snippetsCollection = db.collection('snippets')
const likesCollection = db.collection('likes')
const notesCollection = db.collection('notes')
const categoriesCollection = db.collection('categories')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  postsCollection,
  snippetsCollection,
  commentsCollection,
  likesCollection,
  notesCollection,
  categoriesCollection
}