<template>
<div class="content">
    <div class="content-area">
        <div class="posted-by">
            <div>{{ userProfile.name }}</div>
            <div>{{ userProfile.title }}</div>
        </div>
        <div class="create-post">
            <h2>Create a post</h2>
            <form @submit.prevent>
                <input type="text" placeholder="Post title" v-model.trim="post.title">
                <selectCat :options="cats" :default="'Select Cat'" @input="displayToKey($event)" />
                <vueditor :content="post.content" @changed="getVal"></vueditor>
                <div class="post-button">
                    <button @click="createPost()" :disabled="post.content === ''" class="btn">post</button>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import selectCat from './components/selectCat'

export default {
    title: 'Add Article',
    components: {
        selectCat,
    },
    data() {
        return {
            post: {
                title: '',
                content: '',
                cat: '',
            },
        }
    },
    computed: {
        ...mapState(['userProfile', 'cats'])
    },
    methods: {
        getVal: function (newVal) {
            this.post.content = newVal
        },
        createPost() {
            this.$store.dispatch('createPost', {
                content: this.post.content,
                title: this.post.title,
                category: this.post.cat
            })
            this.post.title = ''
            this.post.content = ''
            this.post.cat = ''

            this.$router.push('/articles')
        },
        displayToKey(e) {
          this.post.cat = e.name
        }
    }
}
</script>
