<template>
  <div class="box" v-click-outside="close">
    <a class="icon-delete" @click="deleteNote(note.id)"></a>
    <div class="tools" :class="{ active: isOpen }">
      <a class="icon-dots" @click="isOpen = !isOpen"></a>
      <div class="btn-wrap">
        <button class="icon-bold" @click.prevent="stylize('bold')"></button>
        <button class="icon-italic" @click.prevent="stylize('italic')"></button>
        <button class="icon-underline" @click.prevent="stylize('underline')"></button>
        <button class="icon-superscript" @click.prevent="stylize('superscript')"></button>
        <button class="icon-subscript" @click.prevent="stylize('subscript')"></button>
        <button
          class="icon-strikethrough"
          @click.prevent="stylize('strikeThrough')"
        ></button>
        <button class="icon-pilcrow" @click.prevent="stylize('insertParagraph')"></button>
        <button
          class="icon-left-quote"
          @click.prevent="stylize('formatBlock', false, 'BLOCKQUOTE')"
        ></button>
        <button
          class="icon-number-list"
          @click.prevent="stylize('insertOrderedList')"
        ></button>
        <button
          class="icon-list"
          @click.prevent="stylize('insertUnorderedList')"
        ></button>
      </div>
    </div>
    <div
      @click="zoomAct"
      class="ncontent"
      v-html="note.content"
      contentEditable="true"
      @blur="updateCode($event, note.id)"
      ref="not"
    ></div>
  </div>
</template>
<script>
import { notesCollection } from "@/firebase";
export default {
  props: ["note"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    updateCode: (event, id) => {
      notesCollection.doc(id).update({
        content: event.target.innerHTML,
      });
    },
    deleteNote(id) {
      this.$parent.$emit("del", id);
    },
    close() {
      this.isOpen = false;
    },
    stylize: function (style, ui, value) {
      var inui = false;
      var ivalue = null;
      if (arguments[1]) {
        inui = ui;
      }
      if (arguments[2]) {
        ivalue = value;
      }
      document.execCommand(style, inui, ivalue);
    },
    zoomAct() {
      if(this.$refs.not.clientHeight > 249) this.$emit('zoomAct')
    }
  }
};
</script>
