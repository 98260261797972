<template>
<div class="content">
    <div class="content-area">
      <div class="posted-by">
        <div>{{ article.userName }}</div>
        <div>{{ article.createdOn | formatDate }}</div>
        <div v-if="!isEdit">{{ article.title }}</div>
        <div v-else>
          <input class="input" type="text" v-model.trim="title">
        </div>
        <div v-if="!isEdit"><a @click="openCommentModal" class="btn">Comment</a></div>
        <selectCat v-if="editAccess" :clickable="isEdit ? true : false" :options="cats" :default="article.category" @input="displayToKey($event)" />
        <div v-if="editAccess">
          <a v-if="!isEdit" @click="edit" class="btn">Edit</a>
          <a v-else @click="save" class="btn">Save</a>
          <a @click="openModal" class="btn">Delete</a>
        </div>
        <div v-if="!isEdit">likes {{ article.likes }}</div>
      </div>
      <div class="single-post" v-if="!isEdit" v-html="article.content"></div>
      <vueditor v-else :content="content" @changed="getVal"></vueditor>
      <div v-if="postComments.length && !isEdit" class="comments">
        <div v-for="comment in postComments" :key="comment.id" class="comment">
          <p class="commented-by">{{ comment.userName }}</p>
          <span>{{ comment.createdOn | formatDate }}</span>
          <p>{{ comment.content }}</p>
        </div>
      </div>
  </div>

  <transition name="fade">
    <CommentModal v-if="showCommentModal" :post="article" @close="closeCommentModal"></CommentModal>
    <ConfirmModal title="Are you sure want to delete this snippet? <br> It will delete all comments and likes as well." @delete="del" @close="closeModal" v-if="modal" />
  </transition>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { commentsCollection, postsCollection, likesCollection } from '@/firebase'
import CommentModal from '@/components/CommentModal'
import { highlight, languages } from 'prismjs/components/prism-core';
import ConfirmModal from '../../components/ConfirmModal'
import selectCat from './components/selectCat'
export default {
  components: {
    CommentModal,
    ConfirmModal,
    selectCat
  },
  data() {
    return {
      article: {},
      showCommentModal: false,
      postComments: [],
      isEdit: false,
      content: '',
      cat: '',
      modal: false,
      title: ''
    }
  },
  computed: {
    ...mapState(['userProfile', 'posts', 'cats']),
    editAccess () {
      return (this.userProfile.name === this.article.userName)
    }
  },
  watch: {
    '$route'() {
      this.getArticle()
      this.getComment()
      this.save()
      this.titleUpdated()
    }
  },
  methods: {
    async getArticle() {
      const sid = this.$route.params.id
      this.article = this.posts.find(x => x.id === sid )
      this.title = this.article.title
    },
    async getComment() {
      const sid = this.$route.params.id
      const docs = await commentsCollection.where('postId', '==', sid).get()

      docs.forEach((doc) => {
        let comment = doc.data()
        comment.id = doc.id
        this.postComments.push(comment)
      })
    },
    highlighter(code) {
      return highlight(code, languages.js, "js");
    },
    openCommentModal() {
      this.showCommentModal = true
    },
    closeCommentModal() {
      this.showCommentModal = false
      this.postComments = []
      this.getComment()
    },
    titleUpdated() {
      document.title = `Articles | ${this.article.title}`
    },
    getVal: function (newVal) {
      this.content = newVal
    },
    edit() {
      this.isEdit = !this.isEdit

      this.content = this.article.content
    },
    async save() {
      const id = this.$route.params.id
      await postsCollection.doc(id).update({
        content: this.content,
        category: this.cat,
        title: this.title
      })

      this.isEdit = false
      this.article.content = this.content
      this.article.title = this.title
    },
    async del() {
      const id = this.$route.params.id;
      const comments = await commentsCollection.where('postId', '==', id).get()
      const likes = await likesCollection.where('postId', '==', id).get()
      await postsCollection.doc(id).delete();

      comments.forEach((comment) => {
        comment.ref.delete()
      })

      likes.forEach((like) => {
        like.ref.delete()
      })
      this.closeModal();
      this.$router.push('/articles')
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false
    },
    displayToKey(e) {
      this.cat = e.name ? e.name : this.article.category
    }
  },

  created() {
    this.getArticle()
    this.getComment()
    this.titleUpdated()
  }
}
</script>