import Vue from 'vue'
import moment from 'moment'
Vue.filter('formatDate', (val) => {
    if (!val) {
        return '-'
    }
    let date = val.toDate()
    return moment(date).fromNow()
})

Vue.filter('trimLength', (val, num=200) => {
    if (val.length < num) { return val }
    return `${val.substring(0, num)}...`
})

Vue.filter('removeHtml', (str) => {
    if ((str === null) || (str===''))
        return false
    else
        str = str.toString()

    return str.replace( /(<([^>]+)>)/ig, '')
})